import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { colors } from 'styles/theme';
import { tabletUp, desktopUp } from 'styles/utils';

import Breakpoint from '../Breakpoint/Breakpoint';

// Shape sizes in pixels
const shapeScaleMapping = {
  circle: {
    sm: { width: 50, height: 50 },
    md: { width: 146, height: 146 },
    lg: { width: 254, height: 254 },
    xl: { width: 360, height: 360 },
  },
  pill: {
    sm: { width: 140, height: 95 },
    md: { width: 210, height: 115 },
    lg: { width: 295, height: 160 },
    xl: { width: 420, height: 230 },
  },
  rectangle: {
    sm: { width: 147, height: 110 },
    md: { width: 245, height: 183 },
    lg: { width: 350, height: 260 },
    xl: { width: 500, height: 375 },
  },
};

const shapeCss = ({
  color,
  size,
  variant,
  zIndex,
  bottom,
  top,
  left,
  right,
  rotate,
}) => {
  const shapeDetails = shapeScaleMapping?.[variant]?.[size];
  const width = shapeDetails?.width ?? 0;
  const height = shapeDetails?.height ?? 0;

  return css`
    background-color: ${colors[color]};
    z-index: ${zIndex};
    position: absolute;
    bottom: ${bottom && `${bottom}px`};
    top: ${top && `${top}px`};
    right: ${right && `${right}px`};
    left: ${left && `${left}px`};

    /* On small-medium screens, scale the shape down by 20% */
    width: ${width * 0.8}px;
    height: ${height * 0.8}px;

    ${tabletUp} {
      /* On medium screens, change position according to shape's size */
      bottom: ${bottom && `${bottom * 0.8}px`};
      top: ${top && `${top * 0.8}px`};
      right: ${right && `${right * 0.8}px`};
      left: ${left && `${left * 0.8}px`};
    }

    ${desktopUp} {
      width: ${width}px;
      height: ${height}px;
    }

    /* Get shape-related CSS */
    ${shapeRelatedCss({ rotate, height, variant })}
  `;
};

const shapeRelatedCss = ({ rotate, height, variant }) => {
  const relatedCss = {
    circle: css`
      border-radius: 50%;
    `,
    pill: css`
      border-radius: ${height / 2}px;
      transform: rotate(${rotate ?? -2}deg);
    `,
    rectangle: css`
      border-radius: ${height / 5}px;
      transform: rotate(${rotate ?? -20}deg);
    `,
  };

  return relatedCss[variant];
};

/**
 * @param {object} props
 */
function Shape({
  className,
  color = 'pale',
  size = 'sm',
  variant = 'pill',
  top,
  bottom,
  left,
  right,
  rotate,
  zIndex,
  mobile,
  tablet,
  desktop,
}) {
  return (
    <Breakpoint
      mobile={mobile}
      tablet={tablet}
      desktop={desktop}
      css={shapeCss({
        color,
        size,
        variant,
        zIndex,
        bottom,
        top,
        left,
        right,
        rotate,
      })}
      className={className}
    ></Breakpoint>
  );
}

Shape.propTypes = {
  className: PropTypes.string,
  /** Shape's background color */
  color: PropTypes.oneOf([
    'pale',
    'purple',
    'mildPurple',
    'darkPurple',
    'brightYellow',
    'sunYellow',
    'white',
    'offWhite',
  ]),
  /** Shape's vertical bottom position */
  bottom: PropTypes.number,
  /** Shape's horizontal left position */
  left: PropTypes.number,
  /** Shape's horizontal right position */
  right: PropTypes.number,
  /** Override shape's rotation degree */
  rotate: PropTypes.number,
  /** Shape's scale size */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /** Shape's vertical top position */
  top: PropTypes.number,
  /** Shape's z-index */
  zIndex: PropTypes.number,
  /** Shape's variant */
  variant: PropTypes.oneOf(['circle', 'pill', 'rectangle']),
  /** Show on mobile only */
  mobile: PropTypes.bool,
  /** Show on tablet only */
  tablet: PropTypes.bool,
  /** Show on desktop only */
  desktop: PropTypes.bool,
};

export default Shape;
